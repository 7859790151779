import { TYPE } from "vue-toastification";
import { apiCall, logResponse } from "@/core/api";
import { showAlert } from "@/core/popup";

export async function getTenantList(): Promise<any> {
  const resource = "/tenants";
  const response = await apiCall("GET", resource);
  if (response.code === 200) {
    return response.result;
  }
  logResponse(response);
  return undefined;
}

export async function getTenant(tenantId: string): Promise<any> {
  const endPoint = `/tenants/${tenantId}`;
  const response = await apiCall("GET", endPoint);
  if (response.code === 200) {
    return response.result[0];
  }
  logResponse(response);
  return undefined;
}

export async function deleteTenant(tenantId: string): Promise<any> {
  const endPoint = `/tenants/${tenantId}`;
  const response = await apiCall("DELETE", endPoint);
  if (response.code === 200) {
    return true;
  }
  logResponse(response);
  return false;
}

export async function addTenant(tenantConnectorDto: any): Promise<any> {
  const endPoint = "/tenants/create";
  const response = await apiCall("POST", endPoint, tenantConnectorDto);
  if (response.code === 200) {
    if (response.result?.code === "1012") {
      showAlert(TYPE.WARNING, response.message);
      return undefined;
    }
    return response.result;
  }
  logResponse(response);
  return undefined;
}

export async function editTenant(tenantConnectorDto: any): Promise<any> {
  const endPoint = "/tenants/update";
  const response = await apiCall("POST", endPoint, tenantConnectorDto);
  if (response.code === 200) {
    return response.result;
  }
  logResponse(response);
  return undefined;
}

export async function inlineEditTenant(tenantDto: any): Promise<any> {
  const endPoint = "/tenants/edit";
  const response = await apiCall("POST", endPoint, tenantDto);
  if (response.code === 200) {
    return response.result;
  }
  logResponse(response);
  return undefined;
}
