import { TYPE } from "vue-toastification";
import { apiCall, apiCallParams, logResponse } from "@/core/api";
import { showAlert } from "@/core/popup";
import { router } from "@/core/router";

export async function getUsersList(): Promise<any> {
  const endpoint = "/users/all";
  const response = await apiCall("GET", endpoint);
  if (response.code === 200) {
    return response.result;
  }
  logResponse(response);
  return undefined;
}

export async function deleteUser(userId: string): Promise<any> {
  const endpoint = `/users/${userId}`;
  const response = await apiCall("DELETE", endpoint);
  logResponse(response);
  return response;
}

export async function addUser(user: any): Promise<any> {
  const endpoint = "/users";
  const response = await apiCall("POST", endpoint, user);
  if (response.code === 200) {
    await router.push("/users");
    return response.result;
  }
  if (response.code === 409) {
    showAlert(TYPE.WARNING, "User with the same email address already exists. Please try with different email.");
    await router.push("/users/create");
    return undefined;
  }
  if (response.code === 502) {
    showAlert(TYPE.WARNING, "Mail could not be sent.");
    await router.push("/users/create");
    return undefined;
  }
  if (response.code === 401) {
    showAlert(TYPE.WARNING, "Tenant admin user can only be created by a tenant-admin.");
    await router.push("/users/create");
    return undefined;
  }
  logResponse(response);
  return undefined;
}

export async function getOrganizationsByUserId(userId: string): Promise<any> {
  const endPoint = `/users/organizations/${userId}`;
  const response = await apiCall("GET", endPoint);
  if (response.code === 200) {
    return response.result;
  }
  logResponse(response);
  return undefined;
}

export async function getUserById(userId: string): Promise<any> {
  const endpoint = `/users/${userId}`;
  const response = await apiCall("GET", endpoint);
  if (response.code === 200) {
    return response.result[0];
  }
  logResponse(response);
  return undefined;
}

export async function editUser(user: any): Promise<any> {
  const endpoint = "/users";
  const response = await apiCall("POST", endpoint, user);
  if (response.code === 200) {
    return response.result;
  }
  if (response.code === 409) {
    showAlert(TYPE.WARNING, "User with the same email address already exists. Please try with different email.");
    return undefined;
  }
  if (response.code === 502) {
    showAlert(TYPE.WARNING, "Mail could not be sent.");
    return undefined;
  }
  if (response.code === 401) {
    showAlert(TYPE.WARNING, "Tenant admin user can only be edited by a tenant-admin.");
    return undefined;
  }
  if (response.code === 400) {
    showAlert(TYPE.WARNING, "The user you are editing is the only tenant admin. You must assign the tenant admin role to another user before changing the current tenant admin to a regular user.");
    return undefined;
  }
  logResponse(response);
  return undefined;
}

export async function newPassword(activationId: string, password: string): Promise<boolean> {
  const endpoint = "/auth/activate";
  const activation = {
    activationId,
    password,
  };
  const response = await apiCall("POST", endpoint, activation);

  if (response.code === 200) {
    showAlert(TYPE.SUCCESS, "Your password has been successfully changed.");
    return true;
  }
  if (response.code === 404) {
    showAlert(TYPE.ERROR, "Your password could not be changed.");
    return false;
  }
  if (response.code === 403) {
    showAlert(TYPE.ERROR, "Your new password cannot be the same with your old password.");
    return false;
  }
  if (response.code === 409) {
    showAlert(TYPE.ERROR, "Your password is already changed.");
    return false;
  }
  logResponse(response);
  return false;
}

export async function resetPassword(email: string): Promise<boolean> {
  const endpoint = `/users/reset-password/${email}`;

  const response = await apiCall("GET", endpoint);
  if (response.code === 200) {
    return true;
  }

  logResponse(response);
  return false;
}

export async function getUserListing(params: any):Promise<any> {
  const endpoint = "/users/listing";
  const response = await apiCallParams("GET", endpoint, params);
  if (response.code === 200) {
    return response.page;
  }
  logResponse(response);
  return undefined;
}
